@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abril+Fatface|Prata|Revalia&display=swap');
body {
  margin: 0;
  // font-family: 'Prata', serif;
  // font-family: 'Revalia', cursive;
  // font-family: 'Abril Fatface', cursive;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
