@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface|Prata|Revalia&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.App {
  display: flex;
  overflow: hidden;
  background: #394147; }
  .App > * {
    box-sizing: border-box; }
  .App-header {
    background: #394147;
    color: #fafafa;
    padding: 10px;
    display: flex;
    flex-direction: column; }
    .App-header .title {
      margin: 0;
      font-family: "Revalia", cursive;
      font-size: 28px; }
    .App-header .stats {
      display: flex;
      width: 100%;
      align-items: flex-end;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly; }
      .App-header .stats .game-stats {
        margin: 0 10px;
        font-weight: 300; }
        .App-header .stats .game-stats-value {
          font-size: 82px;
          font-weight: bold;
          margin-left: 10px; }
  .App-body {
    margin: 0 auto;
    vertical-align: baseline;
    font-size: 0; }
    .App-body .grid {
      height: 100vh;
      width: 100vh; }
    .App-body .modal-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5); }
      .App-body .modal-backdrop .modal {
        position: absolute;
        width: 300px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #22272a;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.5);
        -webkit-transition: box-shadow .25s ease-in;
        transition: box-shadow .25s ease-in; }
        .App-body .modal-backdrop .modal:hover {
          background-color: #202427;
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); }
        .App-body .modal-backdrop .modal-header {
          border-bottom: 1px solid rgba(243, 243, 243, 0.1);
          margin-bottom: 20px; }
          .App-body .modal-backdrop .modal-header .close {
            width: 20px;
            height: 20px;
            display: block;
            margin-left: auto;
            margin-bottom: 20px;
            cursor: pointer; }
            .App-body .modal-backdrop .modal-header .close:before {
              content: '';
              display: block;
              width: 2px;
              height: 20px;
              background-color: #f3f3f3;
              -webkit-transform: rotate(45deg) translate(6px, -7px);
                      transform: rotate(45deg) translate(6px, -7px); }
            .App-body .modal-backdrop .modal-header .close:after {
              content: '';
              display: block;
              width: 2px;
              height: 20px;
              background-color: #f3f3f3;
              -webkit-transform: rotate(-45deg) translate(21px, -8px);
                      transform: rotate(-45deg) translate(21px, -8px); }
        .App-body .modal-backdrop .modal-body {
          color: #fafafa;
          font-size: 20px; }
          .App-body .modal-backdrop .modal-body h4 {
            text-align: center; }
          .App-body .modal-backdrop .modal-body h3 {
            display: flex;
            align-items: baseline; }
            .App-body .modal-backdrop .modal-body h3 span {
              font-size: 60px;
              margin: 0 0 0 auto; }
    .App-body .cell {
      position: relative;
      margin: 0;
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      display: inline-block;
      box-sizing: border-box;
      line-height: 0;
      font-size: 0;
      background-color: #b9c0c6;
      color: #000;
      -webkit-transition: all .25s ease-in;
      transition: all .25s ease-in; }
      .App-body .cell:hover {
        background-color: #aab4bb;
        cursor: pointer; }
      .App-body .cell .counter {
        font-size: 18px;
        font-weight: 600;
        position: absolute;
        margin-top: 50%;
        margin-left: 50%;
        -webkit-transform: translate3d(-50%, -50%, 0);
                transform: translate3d(-50%, -50%, 0);
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        pointer-events: none; }
      .App-body .cell:nth-child(10n + 1) {
        border-left: 1px solid black; }
      .App-body .cell:nth-child(-n + 10) {
        border-top: 1px solid black; }
      .App-body .cell.selected {
        background: #316A68; }
      .App-body .cell.selectable {
        background: #D0DA5C;
        cursor: pointer; }
  .App-footer {
    height: 5vh;
    background: #394147;
    color: #fafafa;
    padding: 10px;
    display: flex; }
    .App-footer .stats-title {
      margin: 0;
      width: 120px; }
    .App-footer .stats {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center; }
      .App-footer .stats .game-stats {
        margin: 0 10px;
        font-weight: 300; }
        .App-footer .stats .game-stats-value {
          font-size: 25px;
          font-weight: bold; }

