$fatFont: 'Prata', serif;
$futurFont: 'Revalia', cursive;
$AbrilFont: 'Abril Fatface', cursive;

.App {
  display: flex;
  // flex-direction: column;
  overflow: hidden;
  background: #394147;

  >* {
    box-sizing: border-box;
  }

  &-header {
    // height: 7vh;
    background: #394147;
    color: #fafafa;
    padding: 10px;
    // text-align: center;
    display: flex;
    flex-direction: column;

    .title {
      // width: 300px;
      margin: 0;
      font-family: $futurFont;
      font-size: 28px;
    }

    .stats {
      display: flex;
      width: 100%;
      // justify-content: space-around;
      align-items: flex-end;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
      .game-stats {
        margin: 0 10px;
        font-weight: 300;
        &-value {
          font-size: 82px;
          font-weight: bold;
          margin-left: 10px;;
        }
      }

    }

  }

  &-body {
    // height: 93vh;
    // width: 88vh;
    margin: 0 auto;
    vertical-align: baseline;
    font-size: 0;
    .grid {
      height: 100vh;
      width: 100vh;
    }

    .modal-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .5);
      .modal {
        position: absolute;
        width: 300px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #22272a;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.5);
        transition: box-shadow .25s ease-in;
        &:hover {
          // cursor: pointer;
          background-color: darken(#22272a, 1%);
          box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.5);
        }
        &-header {
          border-bottom: 1px solid transparentize(#f3f3f3, .9);
          margin-bottom: 20px;
          .close {
            width: 20px;
            height: 20px;
            display: block;
            margin-left: auto;
            margin-bottom: 20px;
            cursor: pointer;
            &:before {
              content: '';
              display: block;
              width: 2px;
              height: 20px;
              background-color: #f3f3f3;
              transform: rotate(45deg) translate(6px, -7px);
            }
            &:after {
              content: '';
              display: block;
              width: 2px;
              height: 20px;
              background-color: #f3f3f3;
              transform: rotate(-45deg) translate(21px, -8px);
            }
          }
        }
        &-body {
          color: #fafafa;
          font-size: 20px;
          h4 {
            text-align: center;
          }
          h3 {
            display: flex;
            align-items: baseline;
            span {
              font-size: 60px;
              margin: 0 0 0 auto;
            }
          }
        }
        &.-footer {}
      }
    }

    .cell {
      position: relative;
      margin: 0;
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      display: inline-block;
      box-sizing: border-box;
      line-height: 0;
      font-size: 0;
      background-color: lighten(#394147, 50%);
      color: #000;
      transition: all .25s ease-in;
      &:hover {
        background-color: lighten(#394147, 45%);
        cursor: pointer;
      }

      .counter {
        font-size: 18px;
        font-weight: 600;
        position: absolute;
        margin-top: 50%;
        margin-left: 50%;
        transform: translate3d(-50%, -50%, 0);
        user-select: none;
        pointer-events: none;
      }

      &:nth-child(10n + 1) {
        border-left: 1px solid black;
      }

      &:nth-child(-n + 10) {
        border-top: 1px solid black;
      }

      &.selected {
        background: #316A68;
      }

      &.selectable {
        background: #D0DA5C;
        cursor: pointer;
      }
    }
  }

  &-footer {
    height: 5vh;
    background: #394147;
    color: #fafafa;
    padding: 10px;
    display: flex;

    .stats-title {
      margin: 0;
      width: 120px;
    }

    .stats {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      .game-stats {
        margin: 0 10px;
        font-weight: 300;
        &-value {
          font-size: 25px;
          font-weight: bold;
        }
      }

    }
  }
}